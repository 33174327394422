import { Controller } from "@hotwired/stimulus"
import {useIntersection} from "stimulus-use";
// import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ["submit"]
  static values = { recaptcha: { type: Boolean, default: true } }

  connect() {
    this.validateForm()
    console.log('loaded form_validation controller');
  }

  validateForm() {
    this.submitTarget.disabled = !this.formIsValid()
  }

  formIsValid() {
    var requiredFields = Array.from(this.element.elements)
      .filter(input => input.required == true)
    var requiredFieldsPopulated = !requiredFields.some(input => input.value == "")

    var emailFields = Array.from(this.element.elements)
      .filter(input => input.type == 'email')
    var invalidEmailFieldsExist = emailFields
      .filter(input => !input.validity.valid).length > 0

    var recaptchaField = this.element.elements
      .namedItem('g-recaptcha-response')
    var recaptchaCompleted = false
    if (recaptchaField != null) {
      recaptchaCompleted = recaptchaField.value.length > 0
    }

    return requiredFieldsPopulated && !invalidEmailFieldsExist && (this.recaptchaValue == false || recaptchaCompleted)
  }

  submit() {
    if (this.formIsValid() == true) {
      this.element.submit()
    }
  }
}