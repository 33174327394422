import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container", "button"]
    static values = { url: String, urlOrig: String, revealed: Boolean, container: String }

    toggle() {
        if( this.revealedValue == false) {
            $(this.containerTarget).load(this.urlValue)
            let button = $(this.buttonTarget)
            button.html(button.data().secondaryLabel)
        } else {
            $(this.containerTarget).load(this.urlOrigValue)
            let button = $(this.buttonTarget)
            button.html(button.data().primaryLabel)
        }
        this.revealedValue = !this.revealedValue
    }

    hide() {
    }

    disconnect() {
        if (this.hasCargTarget) {
            this.cardTarget.remove();
        }
    }
}