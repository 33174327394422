import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ['tableBody', 'loadButton', 'updateTableSorterElement'];

	load(event) {
		event.preventDefault();

		let url = new URL(this.data.get('url'), document.location.origin);
		url.searchParams.append('offset', this.offsetRows());

		var header = this.element.querySelectorAll('.tablesorter-headerAsc, .tablesorter-headerDesc')[0]
		if(header) {
			var column = header.dataset.sortName
			var order = header.classList.contains('tablesorter-headerAsc') ? 'asc' : 'desc'
			url.searchParams.append('column', column);
			url.searchParams.append('order', order);
		}

		if (this.data.get("include-query-params")) {
			url = this.appendQueryParams(url);
		}

		fetch(url)
			.then(response => {
				status = response.status;
				return response;
			})
			.then(response => response.text())
			.then(html => {
				this.tableBodyTarget.insertAdjacentHTML('beforeend', html);

				// Rails can return a status 203 (:partial_content) to leave the load more button in place
				// A status of 200 (:ok) means we're done, with no more pages of results to follow
				if (status == '200') {
					this.loadButtonTarget.classList.add('hidden');
				}
			});
	}

	// append query params that are not blank
	appendQueryParams(baseURL)  {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		for (const [key, value] of Object.entries(params)) {
			if (value != '' && key != 'offset') { baseURL.searchParams.append(key, value); }
		}

		return baseURL;
	}

	offsetRows() {
		if (this.data.get("list-type")) {
			return this.tableBodyTarget.getElementsByTagName(this.data.get("list-type")).length;
		} else {
			return this.tableBodyTarget.getElementsByTagName('tr').length;
		}
	}
}
