import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  // changed this to only fire on enter so removing debounce
  // static debounces = ["updateUrl"];
  static targets = [
    "purpose",
    "zipcode",
    "lineThickness",
    "palette",
    "routesSwitch",
    "siteId",
    "siteLoader",
    "statusBarSwitch",
    "lineScale",
    "downloadExport",
    "riderPanel",
    "plannerPanel",
    "infoSection",
    "chevronImage",
    "chevronImage2",
    "chevronUp1",
    "chevronDown1",
    "chevronUp2",
    "chevronDown2",
    "drawerContent1",
    "drawerContent2",
    "showHideRoutes",
    "showHideRoutesSwitch",
    "showHideRatedRoutes",
    "showHideRatedRoutesSwitch",
    "showAllRoutesSwitch",
    "ratedRouteSection",
    "timeframe",
    "showHidePins",
    "workTravel",
  ];

  static values = {
    exportBounds: Array,
    userToken: String,
  };

  connect() {
    // useDebounce(this, { wait: 1000 });

    this.formState = {
      minimum_user_threshold: 2,
      hideStardEndSwitch: true,
      hideHomeWorkSwitch: false,
      dateRange: null,
    };

    this.zipcodeTarget.focus();

    this.toggleRatedRouteSection();

    if (this.hasTimeframeTarget) {
      this.timeframeTarget.addEventListener(
        "dateRangeSelected",
        this.updateDateRange.bind(this)
      );
    }
    // // for testing download link
    // const mockData = {
    //   complete: true,
    //   download_url: "https://example.com/mock-download",
    //   status_url: "/mock-status-url",
    // };

    // this.displayExportStatus(mockData);
    // this.displayUnfilteredExportStatus(mockData);
  }

  updateDateRange(event) {
    const { startDate, endDate, reset } = event.detail;

    if (reset == true) {
      this.formState.timeframe = null;
    } else {
      const timeframeString = `${startDate}...${endDate}`;
      this.formState.timeframe = timeframeString;
    }
    this.updateLayer();
  }

  toggleHeight() {
    if (this.riderPanelTarget.style.height === "60px") {
      this.riderPanelTarget.style.height = "";
      this.riderPanelTarget.style.overflow = "";
    } else {
      this.riderPanelTarget.style.height = "60px";
      this.riderPanelTarget.style.overflow = "hidden";
    }

    this.chevronImageTarget.classList.toggle("rotated");
  }

  toggleHeight2() {
    if (this.plannerPanelTarget.style.height === "60px") {
      this.plannerPanelTarget.style.height = "";
      this.plannerPanelTarget.style.overflow = "";
    } else {
      this.plannerPanelTarget.style.height = "60px";
      this.plannerPanelTarget.style.overflow = "hidden";
    }

    this.chevronImage2Target.classList.toggle("rotated");
  }

  showRiderPanel() {
    this.riderPanelTarget.style.display = "block";
    this.plannerPanelTarget.style.display = "none";
    this.riderPanelTarget.style.height = "";
    this.riderPanelTarget.style.overflow = "";
  }

  showPlannerPanel() {
    this.riderPanelTarget.style.display = "none";
    this.plannerPanelTarget.style.display = "block";
    this.plannerPanelTarget.style.height = "";
    this.plannerPanelTarget.style.overflow = "";
  }

  toggleRatedRouteSection(bool) {
    if (this.hasRatedRouteSectionTarget) {
      this.ratedRouteSectionTarget.style.display =
        this.showHideRoutesSwitchTarget.checked || bool === true
          ? "flex"
          : "none";
    }
  }

  toggleDrawerContent1() {
    this.toggleDrawer(
      this.drawerContent1Target,
      this.drawerContent2Target,
      this.chevronUp1Target,
      this.chevronDown1Target,
      document.querySelector(".drawer-paragraph-1"),
      document.querySelector(".drawer-paragraph-2")
    );
  }

  toggleDrawerContent2() {
    this.toggleDrawer(
      this.drawerContent2Target,
      this.drawerContent1Target,
      this.chevronUp2Target,
      this.chevronDown2Target,
      document.querySelector(".drawer-paragraph-2"),
      document.querySelector(".drawer-paragraph-1")
    );
  }

  toggleDrawer(
    drawer,
    otherDrawer,
    chevronUp,
    chevronDown,
    paragraphToHide,
    paragraphToShow
  ) {
    if (drawer.style.display === "block" || !drawer.style.display) {
      drawer.style.display = "none";
      chevronUp.style.display = "none";
      chevronDown.style.display = "block";

      if (paragraphToHide) paragraphToHide.style.display = "block";
    } else {
      drawer.style.display = "block";
      otherDrawer.style.display = "none";
      chevronUp.style.display = "inline";
      chevronDown.style.display = "none";

      if (paragraphToHide) paragraphToHide.style.display = "none";
      if (paragraphToShow) paragraphToShow.style.display = "block";
    }
  }

  updateUrlWithSiteId() {
    const siteId = this.siteLoaderTarget.value;
    const url = new URL(window.location.href);

    url.searchParams.set("site_id", siteId);
    window.history.pushState({}, "", url);
    window.location.reload();
  }

  updateUrl() {
    let query = encodeURIComponent(this.zipcodeTarget.value);
    if (query.length > 0) {
      let mapbox_uri = `https://api.mapbox.com/search/geocode/v6/forward?q=${query}&proximity=ip&access_token=pk.eyJ1IjoiY29ycmVjdGVkdGltZSIsImEiOiJZQWpHMy1JIn0.wtBkQp-NkyRbRdcot5o-9g`;
      fetch(new Request(mapbox_uri))
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          return response.json();
        })
        .then((json) => {
          if (json != null) {
            let [lon, lat] = json.features[0].geometry.coordinates;

            this.dispatch("setLocation", {
              detail: {
                lat: lat,
                lon: lon,
              },
            });
          }
        });
    }
  }

  updateLayer(target) {
    let tile_params = `?max_age=${
      this.maxAge || 3600
    }&cache_bust=${new Date().getTime()}`;

    let minimumUserThresholdAdded = false;

    for (const property in this.formState) {
      if (property === "minimum_user_threshold" && !minimumUserThresholdAdded) {
        tile_params += `&${property}=${this.formState[property]}`;
        minimumUserThresholdAdded = true;
      } else if (
        property !== "hideStardEndSwitch" &&
        property !== "hideHomeWorkSwitch"
      ) {
        tile_params += `&${property}=${this.formState[property]}`;
      }
    }

    if (
      this.formState["hideStardEndSwitch"] &&
      this.formState["hideHomeWorkSwitch"]
    ) {
      tile_params += `&privacy_filter=3`;
    } else if (this.formState["hideStardEndSwitch"]) {
      tile_params += `&privacy_filter=1`;
    } else if (this.formState["hideHomeWorkSwitch"]) {
      tile_params += `&privacy_filter=2`;
    } else {
      tile_params = tile_params.replace(/&privacy_filter=\d+/g, ""); // Clean up any privacy_filter
    }

    if (!minimumUserThresholdAdded) {
      tile_params += `&minimum_user_threshold=${
        this.formState["minimum_user_threshold"] || 2
      }`;
    }

    if (this.formState["purpose"]) {
      tile_params += "&line_width=10";
    }

    // dispatch the final tile_params to the setLocation action
    this.dispatch("setLocation", {
      detail: { tile_params: tile_params },
    });

    // reapply colors if there are any selected colors
    if (
      this.formState.selectedColors &&
      this.formState.selectedColors.length > 0
    ) {
      this.dispatch("setLocation", {
        detail: { selected_route_colors: this.formState.selectedColors },
      });
    }
  }

  updateField(event) {
    // let value = event.target.value;
    let selected = event.target.selectedOptions;
    var convertedValue = null;
    if (selected != undefined) {
      let values = Array.from(selected).map((opt) => {
        return opt.getAttribute("value");
      });
      convertedValue = encodeURIComponent(values);
    } else {
      convertedValue = event.target.value;
    }

    // const attrName = event.target.dataset.journeyMapConfigTarget;
    const attrName = event.target.dataset["maps-JourneyMapConfigTarget"];

    if (!convertedValue || convertedValue === "reset") {
      delete this.formState[attrName];
    } else {
      this.formState[attrName] = convertedValue;
    }

    this.updateLayer(attrName);
  }

  updateCheckbox(event) {
    if (event.target.checked) {
      this.formState[event.target.dataset.target] = "true";
    } else {
      delete this.formState[event.target.dataset.target];
    }
    // console.log("STATE AFTER", this.formState);
    this.updateLayer(event.target.dataset.target);
  }

  updateLineScale() {
    this.dispatch("setLocation", {
      detail: { line_scale: this.lineScaleTarget.value },
    });
  }

  updateLineThicknessClick(event) {
    const lineThickness = event.currentTarget.dataset.lineThicknessValue;
    this.lineThicknessTarget.value = lineThickness;
    this.dispatch("setLocation", {
      detail: { line_thickness: lineThickness },
    });
  }

  updatePaletteClick(event) {
    const palette = event.currentTarget.dataset.paletteValue;
    this.paletteTarget.value = palette;
    this.dispatch("setLocation", {
      detail: { palette: palette },
    });
  }

  updateLineThickness() {
    this.dispatch("setLocation", {
      detail: { line_thickness: this.lineThicknessTarget.value },
    });
  }

  updateBaseMap(event) {
    const baseMapValue = event.currentTarget.dataset.baseMap;
    this.dispatch("setLocation", {
      detail: { base_map: baseMapValue },
    });
  }

  updateUnratedRoutes() {
    const allRoutesChecked = this.showAllRoutesSwitchTarget.checked;
    const hideRoutesChecked = this.showHideRoutesSwitchTarget.checked;

    if (allRoutesChecked && hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: true });
    } else if (!allRoutesChecked && !hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: false, show_hide_routes: false });
      this.toggleRatedRouteSection();
    } else if (!allRoutesChecked && hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: false });
    } else if (allRoutesChecked && !hideRoutesChecked) {
      this.dispatchSettings({ hide_routes_show_gray: false });
      this.toggleRatedRouteSection(false);
    }
  }

  updateShowHideRoutes() {
    const allRoutesChecked = this.showAllRoutesSwitchTarget.checked;
    const hideRoutesChecked = this.showHideRoutesSwitchTarget.checked;

    if (allRoutesChecked && hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: true });
      this.toggleRatedRouteSection(true);
    } else if (!allRoutesChecked && !hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: false, show_hide_routes: false });
      this.toggleRatedRouteSection();
    } else if (!allRoutesChecked && hideRoutesChecked) {
      this.dispatchSettings({ hide_unrated: false, show_hide_routes: true });
      this.toggleRatedRouteSection();
    } else if (allRoutesChecked && !hideRoutesChecked) {
      this.dispatchSettings({ show_hide_routes: false });
      this.toggleRatedRouteSection(false);
    }
  }

  dispatchSettings(details) {
    for (const [key, value] of Object.entries(details)) {
      this.dispatch("setLocation", { detail: { [key]: value } });
    }
  }

  updateShowHidePins() {
    this.dispatchSettings({ show_hide_pins: this.showHidePinsTarget.checked });
  }

  updateRouteColor(event) {
    const currentSpan = event.currentTarget;
    const color = currentSpan.dataset.color;

    if (!this.formState.selectedColors) {
      this.formState.selectedColors = [];
    }

    if (currentSpan.classList.contains("active")) {
      currentSpan.classList.remove("active");
      this.formState.selectedColors = this.formState.selectedColors.filter(
        (selectedColor) => selectedColor !== color
      );
    } else {
      currentSpan.classList.add("active");
      this.formState.selectedColors.push(color);

      const showHideRoutesCheckbox = this.showHideRoutesSwitchTarget;
      if (showHideRoutesCheckbox) {
        showHideRoutesCheckbox.checked = true;
      }
    }

    if (this.formState.selectedColors.length === 0) {
      this.showAllRoutesSwitchTarget.checked = true;
      this.dispatch("setLocation", {
        detail: { show_hide_routes: true },
      });
    } else {
      this.showAllRoutesSwitchTarget.checked = false;
      this.dispatch("setLocation", {
        detail: { selected_route_colors: this.formState.selectedColors },
      });
    }
  }

  exportRequest(url, formData, exportStatusCallback) {
    let mapElement = document.getElementById("map-container");
    formData.append("subdivisions", 1);
    formData.append("token", this.userTokenValue);

    if (this.hasExportBoundsValue) {
      formData.append("bounds", JSON.stringify(this.exportBoundsValue));
    } else {
      formData.append("bounds", "[" + mapElement.dataset.bounds + "]");
    }

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        let data = JSON.parse(json);
        exportStatusCallback(data);
      });
  }

  requestExport(event) {
    event.target.classList.add("disabled-div");
    let url = new URL(event.target.dataset.url);
    const formData = new FormData();

    for (const [key, value] of Object.entries(this.formState)) {
      formData.append(key, value);
    }

    this.exportRequest(url, formData, this.displayExportStatus.bind(this));
  }

  requestUnfilteredExport(event) {
    event.target.classList.add("disabled-div");
    let url = new URL(event.target.dataset.url);
    const formData = new FormData();

    this.exportRequest(
      url,
      formData,
      this.displayUnfilteredExportStatus.bind(this)
    );
  }

  manageResetExportButton() {
    let existingLink = document.querySelector(
      ".comfort-map__reset-download-link"
    );
    if (!existingLink) {
      let resetExportButton = document.createElement("span");
      resetExportButton.innerText = "Refresh Export";
      resetExportButton.classList.add("comfort-map__reset-download-link");
      resetExportButton.addEventListener("click", () => {
        this.resetExport();
        resetExportButton.remove();
      });
      document.getElementById("export-intro").appendChild(resetExportButton);
    }
  }

  handleExportCompletion(
    data,
    buttonId,
    progressId,
    completeId,
    containerId,
    filename
  ) {
    if (data.complete === true && data.download_url !== undefined) {
      let button = document.getElementById(buttonId);
      button.classList.remove("disabled-div");
      button.removeAttribute("data-action");
      button.classList.add("comfort-map__select-div--base-downloaded");
      this.manageResetExportButton();

      let exportProgress = document.getElementById(progressId);
      let exportComplete = document.getElementById(completeId);

      if (exportProgress) {
        exportProgress.style.display = "none";
      }

      if (exportComplete) {
        exportComplete.style.display = "flex";
      }

      let downloadCompletedContainer = document.getElementById(containerId);
      downloadCompletedContainer.innerHTML = "";

      let downloadLink = document.createElement("a");
      downloadLink.href = data.download_url;
      downloadLink.innerText = "Download Export";
      downloadLink.classList.add("comfort-map__download-link");
      downloadLink.setAttribute("download", filename);
      downloadLink.addEventListener("click", function () {
        window.location.href = data.download_url;
      });
      downloadCompletedContainer.appendChild(downloadLink);
    } else {
      window.setTimeout(() => {
        fetch(data.status_url)
          .then((response) => response.text())
          .then((json) => {
            let data = JSON.parse(json);
            this.handleExportCompletion(
              data,
              buttonId,
              progressId,
              completeId,
              containerId,
              filename
            );
          });
      }, 10000);
    }
  }

  displayExportStatus(data) {
    this.handleExportCompletion(
      data,
      "request-data-button",
      "export-progress",
      "export-complete",
      "export-download-container-downloaded",
      "export.geojson"
    );
  }

  displayUnfilteredExportStatus(data) {
    this.handleExportCompletion(
      data,
      "request-all-data-button",
      "export-all-progress",
      "export-all-complete",
      "export-all-download-container-downloaded",
      "all-data.geojson"
    );
  }

  resetExport() {
    const exportProgress = document.getElementById("export-progress");
    const exportComplete = document.getElementById("export-complete");
    const exportDownloadContainer = document.getElementById(
      "export-download-container-downloaded"
    );
    const requestDataButton = document.getElementById("request-data-button");

    if (exportProgress && exportComplete && requestDataButton) {
      exportProgress.style.display = "flex";
      exportComplete.style.display = "none";
      exportDownloadContainer.innerHTML = "";
      requestDataButton.classList.remove(
        "comfort-map__select-div--base-downloaded"
      );
      requestDataButton.classList.remove("disabled-div");
      requestDataButton.setAttribute(
        "data-action",
        "click->maps--journey-map-config#requestExport"
      );
    }

    // reset for unfiltered export button
    const exportAllProgress = document.getElementById("export-all-progress");
    const exportAllComplete = document.getElementById("export-all-complete");
    const exportAllDownloadContainer = document.getElementById(
      "export-all-download-container-downloaded"
    );
    const requestAllDataButton = document.getElementById(
      "request-all-data-button"
    );

    if (exportAllProgress && exportAllComplete && requestAllDataButton) {
      exportAllProgress.style.display = "flex";
      exportAllComplete.style.display = "none";
      exportAllDownloadContainer.innerHTML = "";
      requestAllDataButton.classList.remove(
        "comfort-map__select-div--base-downloaded"
      );
      requestAllDataButton.classList.remove("disabled-div");
      requestAllDataButton.setAttribute(
        "data-action",
        "click->maps--journey-map-config#requestUnfilteredExport"
      );
    }

    this.manageResetExportButton();
  }
}
